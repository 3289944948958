import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
// import _ from "lodash";

// the translations with the language code from local storage

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en-US",
    lng: "en-US",
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    interpolation: {
      escapeValue: false,
    },
  });

// i18n.languages = ["en-US", "hr"];

// if (localStorage.getItem("language") !== null) {
//   i18n.changeLanguage(localStorage.getItem("language"));
// } else if (_.includes(i18n.languages, navigator.language)) {
//   i18n.changeLanguage(navigator.language);
//   localStorage.setItem("language", "en-US");
// }

export default i18n;
